import { forwardRef, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './CheckBox.module.scss';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  labelClassName?: string;
  boldLabel?: boolean;
};

const CheckBox = forwardRef<HTMLInputElement, Props>(
  ({ name, label, labelClassName, boldLabel, className, ...rest }, ref) => {
    return (
      <div className={cn(styles.checkBoxContainer, className)}>
        <input
          className={styles.checkBoxInput}
          ref={ref}
          name={name}
          id={name}
          type="checkbox"
          {...rest}
        />

        <label
          htmlFor={name}
          className={cn(
            styles.checkBoxLabel,
            { [styles.bold]: boldLabel },
            labelClassName
          )}
        >
          {label}
        </label>
      </div>
    );
  }
);

CheckBox.displayName = 'CheckBox';

export default CheckBox;
