// Lib
import {
  login,
  logOut,
  loginForReStreaming,
  getPages,
  FacebookLoginSuccessResult,
  FacebookGetPagesSuccessResult,
} from 'lib/facebook-sdk';
// Components
import { showToast } from 'components/common/Toast/Toast';

type UseFbSDKReturnType = {
  login: (isStore?: boolean) => Promise<FacebookLoginSuccessResult>;
  loginForReStreaming: () => Promise<FacebookLoginSuccessResult>;
  logOut: () => Promise<void>;
  getPages: () => Promise<FacebookGetPagesSuccessResult>;
};

export const useFbSDK = (): UseFbSDKReturnType => {
  const safeLogout = async () => {
    try {
      await logOut();
    } catch (e) {
      showToast({
        type: 'warning',
        message: 'Something went wrong while facebook logout.',
      });

      console.error('Failed to logout (facebook provider)', e);
    }
  };

  return {
    login,
    loginForReStreaming,
    logOut: safeLogout,
    getPages,
  };
};
