import { Area } from 'react-easy-crop/types';
import { isSafari } from 'react-device-detect';

type GetCroppedImageFile = (
  url: string,
  filename: string,
  mimeType: string
) => Promise<File>;

type ComputeLimitSize = {
  width: number;
  height: number;
};

export const getCroppedImageFile: GetCroppedImageFile = async (
  url,
  filename,
  mimeType
) => {
  const result = await fetch(url);
  const buffer = await result.arrayBuffer();

  return new File([buffer], filename, { type: mimeType });
};

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const computeLimitSize = (size: ComputeLimitSize): ComputeLimitSize => {
  if (isSafari) {
    // Safari maximum pixels
    const maximumPixels = 16777216;
    const { width, height } = size;
    const requiredPixels = width * height;

    if (requiredPixels <= maximumPixels) {
      return { width, height };
    }

    const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);

    return {
      width: Math.floor(width * scalar),
      height: Math.floor(height * scalar),
    };
  }

  return size;
};

export const getEasyCroppedImage = async (
  imageSrc: string,
  pixelCrop: Area,
  fileType: string
): Promise<string> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const limitSize = computeLimitSize(pixelCrop);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    limitSize.width,
    limitSize.height
  );

  canvas.width = limitSize.width;
  canvas.height = limitSize.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    try {
      resolve(canvas.toDataURL(fileType));
    } catch (e) {
      reject(e);
    }
  });
};
