import {
  login,
  logOut,
  loginForReStreaming,
  GoogleLoginSuccessResult,
} from 'lib/google-sdk';
// Hooks
import { useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';

type UseGoogleSDKReturnType = {
  login: () => void;
  logOut: () => Promise<void>;
  loginForReStreaming: () => Promise<GoogleLoginSuccessResult>;
  failedOnLoad: boolean;
};

export const useGoogleSDK = (): UseGoogleSDKReturnType => {
  const { isGoogleSdkFailed } = useAppContext();

  const safeLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      showToast({
        type: 'warning',
        message: 'Something went wrong while google logout.',
      });

      console.error('Failed to logout (google provider)', error);
    }
  };

  return {
    login,
    loginForReStreaming,
    failedOnLoad: isGoogleSdkFailed,
    logOut: safeLogout,
  };
};
