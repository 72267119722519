import { ReactNode } from 'react';
import cn from 'classnames';
import Link, { LinkProps } from 'next/link';
// Types
import { BasicButtonProps } from 'ui2/Button/Button';
// Ui
import Icon from 'ui/Icon/Icon';
// Ui2
import Loader from 'ui2/Loader/Loader';
// Styles
import styles from './LinkButton.module.scss';

type LinkButtonProps = BasicButtonProps & {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  notAllowed?: boolean;
  target?: '_self' | '_blank';
  title?: string;
  rel?: string;
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
} & Omit<LinkProps, 'children'>;

const LinkButton = ({
  className,
  children,
  color = 'black',
  xs = 'default',
  s,
  m,
  ml,
  l,
  xl,
  xxl,
  loading,
  disabled,
  notAllowed,
  href,
  startIcon,
  endIcon,
  target = '_self',
  title,
  rel,
  onClick,
  ...linkProps
}: LinkButtonProps) => {
  const linkClassName = cn(
    styles.link,
    styles[`color-${color}`],
    { [styles[`size-xs-${xs}`]]: xs },
    { [styles[`size-s-${s}`]]: s },
    { [styles[`size-m-${m}`]]: m },
    { [styles[`size-ml-${ml}`]]: ml },
    { [styles[`size-l-${l}`]]: l },
    { [styles[`size-xl-${xl}`]]: xl },
    { [styles[`size-xxl-${xxl}`]]: xxl },
    { [styles.withEndIcon]: !!endIcon },
    { [styles.withStartIcon]: !!startIcon },
    { [styles.disabled]: disabled },
    { [styles.notAllowed]: notAllowed },
    { [styles.loading]: loading },
    className
  );
  const linkChildren = (
    <>
      {loading && (
        <Loader
          loading={loading}
          size="small"
          color={
            color === 'black' || color === 'black-gold'
              ? 'harvest-gold'
              : color === 'harvest-gold'
              ? 'black'
              : 'white'
          }
        />
      )}
      <span className={styles.buttonContent}>
        {startIcon && <Icon name={startIcon} className={styles.buttonIcon} />}
        {children}
        {endIcon && <Icon name={endIcon} className={styles.buttonIcon} />}
      </span>
    </>
  );

  if (
    typeof href === 'string' &&
    (href.startsWith('http') || href.includes('@'))
  ) {
    return (
      <a
        href={href}
        target={target}
        title={title}
        rel={rel}
        onClick={onClick}
        className={linkClassName}
      >
        {linkChildren}
      </a>
    );
  } else {
    return (
      <Link href={!disabled ? href : ''} prefetch={false} {...linkProps}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          className={linkClassName}
          target={target}
          title={title}
          onClick={onClick}
        >
          {linkChildren}
        </a>
      </Link>
    );
  }
};

export default LinkButton;
