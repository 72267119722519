import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
// Lib
import * as ga from 'lib/google-analytics';
import * as fbq from 'lib/fpixel';
// Api
import { ADD_MERCH_ITEM_TO_CART } from 'api/merch/mutations';
// Types
import {
  AddMerchItemToCart,
  AddMerchItemToCartVariables,
} from 'api/merch/types/AddMerchItemToCart';
// Hooks
import { useGetCurrUser } from 'hooks';
import { useAppContext } from 'hooks';
// Helpers
import { getMerchShoppingCartFromLocalStorage } from 'helpers/storage';
// Constants
import { ORDER_MERCH, CHECKOUT } from 'constants/routes';

/**
 * This is a workaround to avoid multiple requests to add merch items to cart,
 * because of 2 re-renders of the app,
 * in cases of Social singin with redirect (Twitter, Tiktok)
 **/
const LOCAL_STORAGE_SHOPPING_CART_SYNCHING = 'SHOPPING_CART_SYNCHING';

const ShoppingCartWatcher = () => {
  const { token, clearMerchItemsFromShoppingCart } = useAppContext();
  const { events, pathname } = useRouter();
  const { data: userData, updateQuery } = useGetCurrUser();

  const [addMerchItemToCart] = useMutation<
    AddMerchItemToCart,
    AddMerchItemToCartVariables
  >(ADD_MERCH_ITEM_TO_CART);

  useEffect(() => {
    // get shopping cart from localStorage directly instead of "merchShoppingCart" value from the "useAppContext" to be sure that we get the latest values from all browser tabs
    const localMerchShoppingCart = getMerchShoppingCartFromLocalStorage();

    if (
      token &&
      localMerchShoppingCart.length &&
      !document.hidden &&
      !localStorage.getItem(LOCAL_STORAGE_SHOPPING_CART_SYNCHING)
    ) {
      const syncShoppingCart = async () => {
        localStorage.setItem(LOCAL_STORAGE_SHOPPING_CART_SYNCHING, 'true');
        const items = localMerchShoppingCart.map((item) => ({
          amount: item.amount,
          variantId: item.variantId,
          memorabiliaId: item.memorabiliaId,
        }));

        try {
          await addMerchItemToCart({
            variables: {
              input: {
                items,
              },
            },
            onCompleted: (data) => {
              updateQuery((prevUserData) => ({
                ...prevUserData,
                me: {
                  ...prevUserData.me,
                  cartSize: data.addMerchItemToCart.length,
                },
              }));
            },
          });

          clearMerchItemsFromShoppingCart();

          /**
           * Clear flag 1 second after emptying local cart to account for app re-rending time
           */
          setTimeout(() => {
            localStorage.removeItem(LOCAL_STORAGE_SHOPPING_CART_SYNCHING);
          }, 1000);
        } catch (error) {
          console.log('error', error);
        }
      };

      syncShoppingCart();
    }
  }, [token, addMerchItemToCart, clearMerchItemsFromShoppingCart, updateQuery]);

  useEffect(() => {
    const trackAbandonedCheckout = (url) => {
      const isPreviousCheckout =
        pathname === CHECKOUT || pathname === ORDER_MERCH;
      const isCurrentCheckout = url === CHECKOUT || url === ORDER_MERCH;
      const isTriggerAbandon = Boolean(
        isPreviousCheckout &&
          !isCurrentCheckout &&
          (userData?.me?.cartSize ?? 0) > 0
      );

      if (isTriggerAbandon) {
        ga.abandonedCheckout();
        fbq.abandonedCheckout();
      }
    };

    events.on('routeChangeStart', trackAbandonedCheckout);

    return () => {
      events.off('routeChangeStart', trackAbandonedCheckout);
    };
  }, [events, userData, pathname]);
  return null;
};

export default ShoppingCartWatcher;
