import cn from 'classnames';

import styles from './ResolutionWrapper.module.scss';

type ResolutionWrapperProps = {
  className?: string;
};

const ResolutionWrapper: React.FC<ResolutionWrapperProps> = ({
  className,
  children,
}) => {
  return <div className={cn(styles.root, className)}>{children}</div>;
};

export default ResolutionWrapper;
