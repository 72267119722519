import React from 'react';
import { RenderLeafProps } from 'slate-react';
// Styles
import styles from './Leaf.module.scss';

export type LeafProps = RenderLeafProps & {
  leaf: {
    bold?: boolean;
    code?: boolean;
    italic?: boolean;
    underline?: boolean;
  };
};

const Leaf = ({ attributes, leaf, children }: LeafProps): JSX.Element => {
  if (leaf.bold) {
    children = <strong className={styles.strong}>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
