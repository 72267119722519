import { useRouter } from 'next/router';
import { useApolloClient, useMutation } from '@apollo/client';
// Api
import { SIGN_OUT } from 'api/auth/mutations';
// Types
import { SignOut, SignOutVariables } from 'api/auth/types/SignOut';
// Constants
import { HOME } from 'constants/routes';
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helpers
import { isDevelopmentEnv } from 'helpers/env';
// Hooks
import { useGoogleSDK, useFbSDK, useAppContext } from 'hooks';

type UseLogout = () => Promise<void>;

/**
 * Global non-reactive variable that gives a way to catch a "logout" process without binding to the "useLogout" instance hook
 */
export let loading = false;

export const useLogout = (): UseLogout => {
  const {
    token,
    clearToken,
    clearMerchItemsFromShoppingCart,
  } = useAppContext();
  const googleSdk = useGoogleSDK();
  const fbSdk = useFbSDK();
  const router = useRouter();
  const client = useApolloClient();

  const [signOut] = useMutation<SignOut, SignOutVariables>(SIGN_OUT);

  const logout = async () => {
    loading = true;
    /**
     * Trigger "fbSdk.logOut()" everywhere except dev env to avoid the error
     * "The method FB.getLoginStatus can no longer be called from http pages."
     */
    if (!isDevelopmentEnv) {
      fbSdk.logOut();
    }

    googleSdk.logOut();

    if (token?.refreshToken) {
      signOut({
        variables: {
          input: {
            refreshToken: token.refreshToken,
          },
        },
      });
    }

    clearToken();
    clearMerchItemsFromShoppingCart();
    localStorage.removeItem(STORE_USER_FIRST_LOGIN);

    await router.push(HOME);

    try {
      await client.resetStore();
    } catch (error) {
      console.error('resetStore', error);
    }

    loading = false;
  };

  return logout;
};
