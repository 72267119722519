import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// Api
import { TIKTOK_SIGN_IN_V2, STORE_TIKTOK_SIGN_UP_V2 } from 'api/auth/mutations';
// Types
import {
  TiktokSignInV2,
  TiktokSignInV2Variables,
  TiktokSignInV2_tiktokSignInV2,
} from 'api/auth/types/TiktokSignInV2';
import {
  StoreTiktokSignUpV2,
  StoreTiktokSignUpV2Variables,
  StoreTiktokSignUpV2_storeTiktokSignUpV2,
} from 'api/auth/types/StoreTiktokSignUpV2';
import { UserRole } from 'api/graphql-global-types';
// Constants
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helpers
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
  getStoreSocialSignupFromLocalStorage,
  clearStoreSocialSignupFromLocalStorage,
} from 'helpers/storage';
import { getProperErrorMessage } from 'helpers/errors';
import { trackFanSignUp } from 'helpers/metrics';
import { getEnvLink } from 'helpers/routes';
import { isStoreRole } from 'helpers/user';
// Hooks
import { useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';

const TiktokSdkWatcher = (): null | JSX.Element => {
  const { setToken } = useAppContext();
  const { push, query } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const [tiktokSignIn] = useMutation<TiktokSignInV2, TiktokSignInV2Variables>(
    TIKTOK_SIGN_IN_V2
  );
  const [storeTiktokSignUp] = useMutation<
    StoreTiktokSignUpV2,
    StoreTiktokSignUpV2Variables
  >(STORE_TIKTOK_SIGN_UP_V2);

  const code = query.code ? decodeURIComponent(query.code as string) : null;

  useEffect(() => {
    if (code) {
      setLoading(true);

      const login = async (): Promise<void> => {
        try {
          const role = getStoreSocialSignupFromLocalStorage();

          let user:
            | StoreTiktokSignUpV2_storeTiktokSignUpV2
            | TiktokSignInV2_tiktokSignInV2
            | undefined;
          let id: string | undefined;
          let accessToken: string | undefined;
          let refreshToken: string | undefined;

          if (isStoreRole(role as UserRole | null)) {
            const { data } = await storeTiktokSignUp({
              variables: {
                input: {
                  code,
                  role: role as UserRole,
                  redirectUrl: getEnvLink(),
                },
              },
            });

            user = data?.storeTiktokSignUpV2;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          } else {
            const { data } = await tiktokSignIn({
              variables: {
                input: {
                  code,
                  redirectUrl: getEnvLink(),
                },
              },
            });

            user = data?.tiktokSignInV2;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          }

          if (id && accessToken && refreshToken) {
            const redirectPath = getRedirectFromLocalStorage();
            const isNewUser = !user?.lastLoggedAt;

            setToken({ id, accessToken, refreshToken, role: role as UserRole });

            if (isNewUser && user?.role === UserRole.Customer) {
              trackFanSignUp(user);
            }

            if (isStoreRole(user?.role)) {
              localStorage.setItem(STORE_USER_FIRST_LOGIN, 'true');
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
                clearStoreSocialSignupFromLocalStorage();
              });
            } else {
              push({
                query: '',
              });
              clearStoreSocialSignupFromLocalStorage();
            }
          }
        } catch (error) {
          push({
            query: '',
          });

          showToast({
            message: getProperErrorMessage(
              error,
              'Looks like something went wrong. Please try again later.'
            ),
            type: 'error',
          });
          console.log('error', error);
          Sentry.captureMessage(`TikTok sign up: ${JSON.stringify(error)}`, {
            tags: {
              section: 'auth',
            },
          });
          clearStoreSocialSignupFromLocalStorage();
        }

        setLoading(false);
      };

      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (loading) {
    return <FourDotsLoader />;
  }

  return null;
};

export default TiktokSdkWatcher;
