import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
// Api
import { MemorabiliaProductType } from 'api/graphql-global-types';
// Types
import { Theme } from 'layouts/Header/Header';
// Hooks
import { useGetCurrUser } from 'hooks';
// Helpers
import { isActiveRoute } from 'helpers/routes';
// Constants
import {
  HOME,
  ATHLETES,
  AMAS,
  MERCH,
  STREAMS,
  PRODUCTS,
  EXPERIENCES,
  ORGANIZATIONS,
} from 'constants/routes';
import {
  isUserAthlete,
  isUserContentCreator,
  isUserOrganization,
} from 'helpers/user';
// Ui
import Icon from 'ui/Icon/Icon';
// Styles
import styles from './DesktopNavigation.module.scss';

const pageLinks = [
  { label: 'Home', href: HOME, subMenu: null },
  { label: 'Athletes', href: ATHLETES, subMenu: null },
  { label: 'Organizations', href: ORGANIZATIONS, subMenu: null },
  {
    label: 'Shop',
    href: null,
    subMenu: [
      {
        label: 'Merch',
        href: {
          pathname: MERCH,
          query: null,
        },
      },
      {
        label: 'Personal Videos',
        href: {
          pathname: AMAS,
          query: null,
        },
      },
      {
        label: 'Products',
        href: {
          pathname: PRODUCTS,
          query: { productType: MemorabiliaProductType.Product },
        },
      },
      {
        label: 'Memorabilia',
        href: {
          pathname: PRODUCTS,
          query: { productType: MemorabiliaProductType.Memorabilia },
        },
      },
      {
        label: 'Experiences',
        href: {
          pathname: EXPERIENCES,
          query: null,
        },
      },
    ],
  },
  {
    label: 'Watch',
    href: STREAMS,
    subMenu: null,
  },
  { label: 'Test item with empty href and subMenu', href: null, subMenu: null },
] as const;
type DesktopNavigationProps = {
  theme?: Theme;
};

const DesktopNavigation = ({ theme = 'white' }: DesktopNavigationProps) => {
  const { pathname, query } = useRouter();
  const { data } = useGetCurrUser();

  const isAthlete = isUserAthlete(data);
  const isOrganization = isUserOrganization(data);
  const isContentCreator = isUserContentCreator(data);
  const isStore = isAthlete || isOrganization || isContentCreator;

  return (
    <nav className={cn(styles.root, { [styles[`theme-${theme}`]]: theme })}>
      {pageLinks.map(({ href, label, subMenu }) => {
        if (subMenu) {
          const isActiveParentMenuLink = subMenu.some((item) =>
            isActiveRoute(pathname, item.href.pathname)
          );

          return (
            <div className={styles.menuWrapper} key={label}>
              <div
                className={cn(styles.parentMenuWrapper, {
                  [styles.active]: isActiveParentMenuLink,
                })}
              >
                <span
                  className={styles.parentMenuLink}
                  tabIndex={0}
                  role="menuitem"
                >
                  {label}
                </span>
                <Icon
                  name="chevron-down-clean"
                  className={styles.dropdownIcon}
                />
              </div>
              <ul className={styles.subMenu}>
                {subMenu.map((item) => {
                  return (
                    <li
                      key={item.label}
                      className={cn(styles.childMenuLink, {
                        [styles.active]: isActiveRoute(
                          pathname,
                          item.href.pathname,
                          item?.href?.query?.productType,
                          query?.productType
                        ),
                      })}
                    >
                      <Link key={item.label} href={item.href} prefetch={false}>
                        <a>{item.label}</a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }

        // Watch button is inverted if user is not a stores(athlete/gym/organization/content creator)
        const isInvertedWatchButton = label === 'Watch' && !isStore;

        if (href) {
          return (
            <Link key={label} href={href} prefetch={false}>
              <a
                className={cn(styles.link, {
                  [styles.active]: isActiveRoute(pathname, href),
                  [styles.inverted]: isInvertedWatchButton,
                })}
              >
                {label}
              </a>
            </Link>
          );
        }

        return null;
      })}
    </nav>
  );
};

export default DesktopNavigation;
