import { useQuery, QueryResult } from '@apollo/client';
// Api
import { ME } from 'api/auth/queries';
// Types
import { Me } from 'api/auth/types/Me';
// Hooks
import { useAppContext } from 'hooks';

export const useGetCurrUser = (): QueryResult<Me> => {
  const { token: contextToken } = useAppContext();
  const skip = !contextToken;

  const data = useQuery<Me>(ME, {
    skip: skip,
    fetchPolicy: 'network-only',
  });

  return data;
};
