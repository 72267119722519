import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// Lib
import { initGoogleSDK } from 'lib/google-sdk';
// Api
import { GOOGLE_SIGN_IN_V2, STORE_GOOGLE_SIGN_UP } from 'api/auth/mutations';
// Types
import {
  GoogleSignInV2,
  GoogleSignInV2Variables,
  GoogleSignInV2_googleSignInV2,
} from 'api/auth/types/GoogleSignInV2';
import {
  StoreGoogleSignUp,
  StoreGoogleSignUpVariables,
  StoreGoogleSignUp_storeGoogleSignUp,
} from 'api/auth/types/StoreGoogleSignUp';
import { UserRole } from 'api/graphql-global-types';
// Constants
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
  getStoreSocialSignupFromLocalStorage,
  clearStoreSocialSignupFromLocalStorage,
} from 'helpers/storage';
import { trackFanSignUp } from 'helpers/metrics';
import { isStoreRole } from 'helpers/user';
// Hooks
import { useDefferInitialization, useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';

const GoogleSdkWatcher = (): null | JSX.Element => {
  const { setToken, setGoogleSkdFailedStatus } = useAppContext();
  const { asPath, push } = useRouter();
  const [isInitialized, setInitialization] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ready = useDefferInitialization();

  const [googleSignInV2] = useMutation<GoogleSignInV2, GoogleSignInV2Variables>(
    GOOGLE_SIGN_IN_V2
  );
  const [storeGoogleSignUp] = useMutation<
    StoreGoogleSignUp,
    StoreGoogleSignUpVariables
  >(STORE_GOOGLE_SIGN_UP);

  const parsedUrl = parse(asPath);
  const code = parsedUrl['/#code'] as string;

  useEffect(() => {
    if (code) {
      setLoading(true);
    }

    if (code && isInitialized) {
      const login = async (): Promise<void> => {
        try {
          const role = getStoreSocialSignupFromLocalStorage();

          let user:
            | StoreGoogleSignUp_storeGoogleSignUp
            | GoogleSignInV2_googleSignInV2
            | undefined;
          let id: string | undefined;
          let accessToken: string | undefined;
          let refreshToken: string | undefined;

          if (isStoreRole(role as UserRole | null)) {
            const { data } = await storeGoogleSignUp({
              variables: {
                input: {
                  code,
                  role: role as UserRole,
                  // setting redirectUrl only for Local env, for others it retrieved from ENV on BE side
                  ...(process.env.NODE_ENV === 'development' && {
                    redirectUrl: 'http://localhost:4000',
                  }),
                },
              },
            });

            user = data?.storeGoogleSignUp;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          } else {
            const { data } = await googleSignInV2({
              variables: {
                input: {
                  code,
                  // setting redirectUrl only for Local env, for others it retrieved from ENV on BE side
                  ...(process.env.NODE_ENV === 'development' && {
                    redirectUrl: 'http://localhost:4000',
                  }),
                },
              },
            });

            user = data?.googleSignInV2;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          }

          if (id && accessToken && refreshToken) {
            const redirectPath = getRedirectFromLocalStorage();
            const isNewUser = !user?.lastLoggedAt;

            setToken({ id, accessToken, refreshToken, role: role as UserRole });

            if (isNewUser && user?.role === UserRole.Customer) {
              trackFanSignUp(user);
            }

            if (isStoreRole(user?.role)) {
              localStorage.setItem(STORE_USER_FIRST_LOGIN, 'true');
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
                clearStoreSocialSignupFromLocalStorage();
              });
            } else {
              push({
                query: '',
              });
              clearStoreSocialSignupFromLocalStorage();
            }
          }
        } catch (error) {
          push({
            query: '',
          });

          showToast({
            message: getProperErrorMessage(
              error,
              'Looks like something went wrong. Please try again later.'
            ),
            type: 'error',
          });
          console.log('error', error);
          Sentry.captureMessage(`Google sign up: ${JSON.stringify(error)}`, {
            tags: {
              section: 'auth',
            },
          });
          clearStoreSocialSignupFromLocalStorage();
        }

        setLoading(false);
      };

      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, isInitialized]);

  useEffect(() => {
    const appId = process.env.NEXT_PUBLIC_GOOGLE_APP_ID;

    /**
     * Load scripts with a delay to improve page speed
     * performance & a metrics score
     */
    if (appId && ready) {
      initGoogleSDK({
        appId,
      })
        .then(() => {
          setInitialization(true);
        })
        .catch((error) => {
          setGoogleSkdFailedStatus(true);
          setLoading(false);
          console.log('initGoogleSDK', error);
        });
    }
  }, [ready, setGoogleSkdFailedStatus]);

  if (loading) {
    return <FourDotsLoader />;
  }

  return null;
};

export default GoogleSdkWatcher;
