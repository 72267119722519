import { useEffect } from 'react';
// Hooks
import { useAppContext } from 'hooks';
// Helpers
import { MERCH_SHOPPING_CART } from 'helpers/storage';

const SyncBrowserTabsWatcher = () => {
  const { addMerchItemToShoppingCart } = useAppContext();

  useEffect(() => {
    const onStorageChange = (e: StorageEvent) => {
      if (e.key === MERCH_SHOPPING_CART && e.newValue) {
        const shoppingCart = JSON.parse(e.newValue);

        addMerchItemToShoppingCart(shoppingCart);
      }
    };

    window.addEventListener('storage', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SyncBrowserTabsWatcher;
