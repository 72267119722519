import cn from 'classnames';
// Types
import { Theme } from 'layouts/Header/Header';
// Hooks
import { useAppContext } from 'hooks';
// Ui2
import Button, { ButtonSize } from 'ui2/Button/Button';
import LinkButton from 'ui2/LinkButton/LinkButton';
// Constants
import { HOST_A_PPV, ADVERTISE } from 'constants/external-links';

import styles from './UnLoggedUserButtons.module.scss';

type MobileHeaderProps = {
  theme?: Theme;
  buttonSize?: ButtonSize;
  className?: string;
  buttonClassName?: string;
  onToggleMobileMenu?: (status: boolean) => void;
};

const UnLoggedUserButtons = ({
  theme = 'white',
  buttonSize = 'small',
  className,
  buttonClassName,
  onToggleMobileMenu,
}: MobileHeaderProps) => {
  const {
    setLoginModalVisibility,
    setChooseSignUpPathModalVisibility,
  } = useAppContext();

  const handleRegisterClick = () => {
    if (onToggleMobileMenu) {
      onToggleMobileMenu(false);
    }
    setChooseSignUpPathModalVisibility(true);
  };

  const handleLoginClick = () => {
    setLoginModalVisibility(true);
  };

  return (
    <>
      <div className={styles.advertiseButtons}>
        <LinkButton
          className={buttonClassName}
          color={theme}
          xs="small"
          href={HOST_A_PPV}
          target="_blank"
          rel="noopener noreferrer"
        >
          HOST A PPV
        </LinkButton>
        <LinkButton
          className={buttonClassName}
          color={theme}
          xs="small"
          href={ADVERTISE}
          target="_blank"
          rel="noopener noreferrer"
        >
          ADVERTISE WITH US
        </LinkButton>
      </div>
      <div className={cn(styles.root, className)}>
        <Button
          className={buttonClassName}
          color={theme}
          xs={buttonSize}
          onClick={handleLoginClick}
        >
          Login
        </Button>
        <Button
          className={buttonClassName}
          color={theme === 'white' ? 'black' : 'white'}
          xs={buttonSize}
          onClick={handleRegisterClick}
        >
          Sign up
        </Button>
      </div>
    </>
  );
};

export default UnLoggedUserButtons;
