import React, { useState } from 'react';
import cn from 'classnames';
// UI
import Icon from 'ui/Icon/Icon';

import styles from './Collapse.module.scss';

type CollapseProps = {
  title: JSX.Element;
  className?: string;
  bodyClassName?: string;
  open?: boolean;
};

const Collapse: React.FC<CollapseProps> = ({
  className,
  bodyClassName,
  title,
  open = false,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(open);
  const toggleCollapse = () => setIsExpanded(!isExpanded);
  return (
    <div
      className={cn(
        styles.collapseWrapper,
        { [styles.isExpanded]: isExpanded },
        className
      )}
    >
      <div
        className={styles.collapseHeader}
        onKeyPress={toggleCollapse}
        onClick={toggleCollapse}
        role="button"
        tabIndex={0}
      >
        <div className={styles.collapseTitle}>{title}</div>
        <button className={styles.collapseToggle} type="button">
          <Icon name="chevron-down" className={styles.icon} />
        </button>
      </div>
      <div className={cn(styles.collapseBody, bodyClassName)}>{children}</div>
    </div>
  );
};

export default Collapse;
