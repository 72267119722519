import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Switch.module.scss';

type SwitchProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'className'
> & {
  label?: string;
  wrapperClassName?: string;
};

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ label, wrapperClassName, name, ...rest }, ref) => {
    return (
      <div className={cn(styles.switchWrapper, wrapperClassName)}>
        <input
          className={styles.switchCheckbox}
          ref={ref}
          id={name}
          name={name}
          type="checkbox"
          {...rest}
        />

        <label htmlFor={name} className={styles.switchLabel}>
          {label}
        </label>
      </div>
    );
  }
);

Switch.displayName = 'Switch';

export default Switch;
