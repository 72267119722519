export type TimeZoneOption = {
  tzCode: string;
  tzName: string;
  label: string;
};

export const TIME_ZONE_OPTIONS: TimeZoneOption[] = [
  {
    tzCode: 'ET',
    tzName: 'Canada/Eastern',
    label: 'Eastern Time (ET)',
  },
  {
    tzCode: 'CT',
    tzName: 'Canada/Central',
    label: 'Central Time (CT)',
  },
  {
    tzCode: 'MT',
    tzName: 'Canada/Mountain',
    label: 'Mountain Time (MT)',
  },
  {
    tzCode: 'PT',
    tzName: 'Canada/Pacific',
    label: 'Pacific Time (PT)',
  },
];
