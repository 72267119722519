import { gql } from '@apollo/client';
// Fragments
import { AuthorizedUserFields } from './fragments';

export const SIGN_UP_ATHLETE_V2 = gql`
  mutation SignUpAthleteV2($input: SignUpAthleteInput!) {
    signUpAthleteV2(input: $input) {
      id
      email
      accessToken
      refreshToken
      firstName
      lastName
      role
    }
  }
`;

export const SIGN_UP_ORGANIZATION = gql`
  mutation SignUpOrganization($input: SignUpOrganizationInput!) {
    signUpOrganization(input: $input) {
      id
      email
      accessToken
      refreshToken
      firstName
      lastName
      role
    }
  }
`;

export const SIGN_UP_CONTENT_CREATOR = gql`
  mutation SignUpContentCreator($input: SignUpContentCreatorInput!) {
    signUpContentCreator(input: $input) {
      id
      email
      accessToken
      refreshToken
      firstName
      lastName
      role
    }
  }
`;

export const STORE_FB_SIGN_UP = gql`
  mutation StoreFbSignUp($input: StoreFbSignInInput!) {
    storeFbSignUp(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      role
    }
  }
  ${AuthorizedUserFields}
`;

export const STORE_GOOGLE_SIGN_UP = gql`
  mutation StoreGoogleSignUp($input: StoreGoogleSignInInput!) {
    storeGoogleSignUp(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      role
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

export const SIGN_UP_CUSTOMER_V2 = gql`
  mutation SignUpCustomerV2($input: SignUpCustomerInput!) {
    signUpCustomerV2(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
    }
  }
  ${AuthorizedUserFields}
`;

export const SIGN_UP_GUEST_V2 = gql`
  mutation SignUpGuestV2($input: SignUpGuestInput!) {
    signUpGuestV2(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGN_IN_V2 = gql`
  mutation SignInV2($input: SignInInput!) {
    signInV2(input: $input) {
      ...AuthorizedUserFields
    }
  }
  ${AuthorizedUserFields}
`;

export const GOOGLE_SIGN_IN_V2 = gql`
  mutation GoogleSignInV2($input: GoogleSignInInput!) {
    googleSignInV2(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

export const FACEBOOK_SIGN_IN_V2 = gql`
  mutation FacebookSignInV2($accessToken: String!) {
    facebookSignInV2(accessToken: $accessToken) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

/** The mutation and its types are used at helpers/auth.ts helper */
export const REFRESH_TOKENS = gql`
  mutation RefreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SignOut($input: SignOutInput!) {
    singOut(input: $input)
  }
`;

export const SAVE_EMAIL = gql`
  mutation SaveEmail($email: String!) {
    saveEmail(email: $email)
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($input: UserUpdateInput!) {
    editUser(input: $input) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
      hasPassword
    }
  }
`;

export const SEND_PASSWORD_RESET_TOKEN = gql`
  mutation SendPasswordResetToken($email: String!) {
    sendPasswordResetToken(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: PasswordResetInput!) {
    resetPassword(passwordResetInput: $input)
  }
`;

export const INIT_TWITTER_AUTH = gql`
  mutation InitTwitterAuth($input: InitAuthTwitterInput!) {
    initTwitterAuth(input: $input) {
      authUrl
    }
  }
`;

export const TWITTER_SIGN_IN = gql`
  mutation TwitterSignIn($input: TwitterSigninInput!) {
    twitterSignIn(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

export const STORE_TWITTER_SIGN_UP = gql`
  mutation StoreTwitterSignUp($input: StoreTwitterSignUpInput!) {
    storeTwitterSignUp(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

export const INIT_TIKTOK_AUTH_V2 = gql`
  mutation InitTiktokAuthV2($input: InitTiktokAuthInput!) {
    initTiktokAuthV2(input: $input) {
      authUrl
    }
  }
`;

export const TIKTOK_SIGN_IN_V2 = gql`
  mutation TiktokSignInV2($input: TiktokSignInV2Input!) {
    tiktokSignInV2(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;

export const STORE_TIKTOK_SIGN_UP_V2 = gql`
  mutation StoreTiktokSignUpV2($input: StoreTiktokSignUpV2Input!) {
    storeTiktokSignUpV2(input: $input) {
      ...AuthorizedUserFields
      firstName
      lastName
      lastLoggedAt
    }
  }
  ${AuthorizedUserFields}
`;
