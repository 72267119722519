import {
  useState,
  forwardRef,
  MouseEventHandler,
  ComponentPropsWithRef,
  Children,
  cloneElement,
} from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
// Ui2
import Text from 'ui2/Text/Text';
import Button, { ButtonColor } from 'ui2/Button/Button';
import Icon from 'ui/Icon/Icon';
// Icons
import InfoIcon from '../../public/images/icons/info.svg';
// Styles
import styles from './Input.module.scss';

export type InputSize = 'large' | 'medium' | 'small';

export type InputProps = {
  name: string;
  label?: string;
  error?: string;
  isReady?: boolean;
  withPasswordToggle?: boolean;
  withDotsInPlaceholder?: boolean;
  fieldSize?: InputSize;
  theme?: Theme;
  action?: {
    label: string | JSX.Element;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    loading?: boolean;
    color?: ButtonColor;
    actionClassName?: string;
    actionAriaLabel?: string;
  };
  prefix?: string;
  notice?: string;
  topNotice?: string;
  tooltipText?: string;
  customInput?: JSX.Element;
} & ComponentPropsWithRef<'input'>;
type Ref = HTMLInputElement;

const Input = forwardRef<Ref, InputProps>(
  (
    {
      name,
      label,
      error,
      className,
      action,
      disabled = false,
      readOnly = false,
      id = name,
      type = 'text',
      isReady = false,
      withPasswordToggle = false,
      withDotsInPlaceholder = false,
      fieldSize = 'large',
      prefix,
      notice,
      topNotice,
      tooltipText,
      theme = 'white',
      customInput,
      ...inputProps
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type);
    const isError = Boolean(error);
    const inputId = `${id}-text-field`;
    const errorId = `${id}-error-text`;
    const showEndAdornment = withPasswordToggle || isReady;
    const inputClassNames = cn(styles.input, {
      [styles[`size-${fieldSize}`]]: !!fieldSize,
      [styles.withPrefix]: prefix,
      [styles.withDotsInPlaceholder]: withDotsInPlaceholder,
    });

    const handlePasswordVisibilityToggle: MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      e.preventDefault();

      if (withPasswordToggle) {
        setInputType((prevState) =>
          prevState === 'password' ? 'text' : 'password'
        );
      }
    };

    return (
      <div className={cn(styles.inputContainerWrapper, className)}>
        <div
          className={cn(styles.inputContainer, {
            [styles[`size-${fieldSize}`]]: !!fieldSize,
            [styles.error]: isError,
            [styles[`theme-${theme}`]]: !!theme,
          })}
        >
          {label && (
            <label
              htmlFor={inputId}
              className={cn(styles.label, {
                [styles[`size-${fieldSize}`]]: !!fieldSize,
              })}
            >
              {label}
              {tooltipText && (
                <>
                  <InfoIcon
                    data-tip
                    id={`${inputId}-hint`}
                    className={styles.tooltipIcon}
                  />
                  <Tooltip
                    anchorId={`${inputId}-hint`}
                    className={styles.tooltip}
                    offset={-3}
                  >
                    <Text
                      color="god-grey"
                      xs="extra-small"
                      textAlign="center"
                      className={styles.tooltipText}
                    >
                      {tooltipText}
                    </Text>
                  </Tooltip>
                </>
              )}
              {topNotice && (
                <Text
                  isEllipsis
                  xs="extra-small"
                  color="light-white"
                  className={styles.topNotice}
                >
                  {topNotice}
                </Text>
              )}
            </label>
          )}

          <div
            className={cn(styles.inputWrapper, {
              [styles.disabled]: disabled,
              [styles.withEndAdornment]: showEndAdornment || action,
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
          >
            {prefix && (
              <span
                className={cn(styles.inputPrefix, {
                  [styles['error']]: !!error,
                  [styles['disabled']]: disabled,
                })}
              >
                {prefix}
              </span>
            )}

            {customInput ? (
              Children.map(customInput, (child) => {
                return cloneElement(
                  child,
                  {
                    className: cn(customInput.props.className, inputClassNames),
                  },
                  null
                );
              })
            ) : (
              <input
                ref={ref}
                id={inputId}
                name={name}
                type={inputType}
                className={inputClassNames}
                aria-invalid={isError}
                aria-describedby={isError ? errorId : undefined}
                disabled={disabled}
                readOnly={readOnly}
                {...inputProps}
              />
            )}

            {showEndAdornment && (
              <div className={styles.endAdornment}>
                {withPasswordToggle && (
                  <button
                    className={styles.passwordToggle}
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibilityToggle}
                    disabled={disabled}
                  >
                    {inputType === 'password' ? (
                      <Icon
                        name="deprecated-eye"
                        className={styles.passwordToggleIcon}
                      />
                    ) : (
                      <Icon
                        name="deprecated-eye-blocked"
                        className={styles.passwordToggleIcon}
                      />
                    )}
                  </button>
                )}
                {isReady && (
                  <Icon
                    name="deprecated-circle-check"
                    className={styles.readyIcon}
                  />
                )}
              </div>
            )}

            {action && (
              <div className={cn(styles.actionWrapper, action.actionClassName)}>
                <Button
                  className={styles.action}
                  onClick={action.onClick}
                  disabled={disabled || action.disabled}
                  loading={action.loading}
                  aria-label={
                    typeof action.label === 'string'
                      ? action.label
                      : action.actionAriaLabel || 'action button'
                  }
                  color={action.color || 'harvest-gold'}
                >
                  {action.label}
                </Button>
              </div>
            )}
          </div>
        </div>

        {notice && (
          <Text
            className={cn(styles.notice, {
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
            xs="extra-small"
            color="black"
          >
            {notice}
          </Text>
        )}

        {isError && (
          <Text
            id={errorId}
            color="maximum-red"
            xs="extra-small"
            className={cn(styles.errorHint, {
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
          >
            {error}
          </Text>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
