import { useState } from 'react';
// Ui
import Modal from 'ui/Modal/Modal';
import Icon from 'ui/Icon/Icon';
// Components
import CreateEditGoLiveStream from 'components/ManageStreams/CreateEditStream/CreateEditGoLiveStream';
// Styles
import styles from './GoLiveButton.module.scss';

const GoLiveButton = () => {
  const [createStreamModal, setCreateStreamModalVisibility] = useState<boolean>(
    false
  );

  const handleCreateStreamModalOpen = () => {
    setCreateStreamModalVisibility(true);
  };

  const handleCreateStreamModalClose = () => {
    setCreateStreamModalVisibility(false);
  };

  const onCompleteCallback = () => {
    setCreateStreamModalVisibility(false);
  };
  return (
    <>
      <button className={styles.root} onClick={handleCreateStreamModalOpen}>
        <Icon name="circle-filled" className={styles.icon} />
        Go Live
      </button>

      <Modal
        open={createStreamModal}
        onClose={handleCreateStreamModalClose}
        title="Create stream"
      >
        <CreateEditGoLiveStream onComplete={onCompleteCallback} />
      </Modal>
    </>
  );
};

export default GoLiveButton;
