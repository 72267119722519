import React from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
// Watchers
import GoogleSdkWatcher from 'layouts/Header/Watchers/GoogleSdkWatcher';
import FacebookSdkWatcher from 'layouts/Header/Watchers/FacebookSdkWatcher';
import TwitterSdkWatcher from 'layouts/Header/Watchers/TwitterSdkWatcher';
import TiktokSdkWatcher from 'layouts/Header/Watchers/TiktokSdkWatcher';
import ShoppingCartWatcher from 'layouts/Header/Watchers/ShoppingCartWatcher';
import SyncBrowserTabsWatcher from 'layouts/Header/Watchers/SyncBrowserTabsWatcher';
import OnboardingWatcher from 'layouts/Header/Watchers/OnboardingWatcher';
import CookieTokenWatcher from './Watchers/CookieTokenWatcher';
// Components
import DesktopHeader from 'layouts/Header/DesktopHeader/DesktopHeader';
import MobileHeader from 'layouts/Header/MobileHeader/MobileHeader';
// Styles
import styles from './Header.module.scss';
// Dynamic imports
const SignUpSignIn = dynamic(
  () => import('components/SignUpSignIn/SignUpSignIn'),
  { ssr: false }
);
const SignUpAthlete = dynamic(
  () => import('components/SignUp/SignUpAthlete/SignUpAthlete'),
  { ssr: false }
);
const SignUpAthleteEmail = dynamic(
  () =>
    import(
      'components/SignUp/SignUpAthlete/SignUpAthleteEmail/SignUpAthleteEmail'
    ),
  { ssr: false }
);
const SignUpAthleteSocial = dynamic(
  () =>
    import(
      'components/SignUp/SignUpAthlete/SignUpAthleteSocial/SignUpAthleteSocial'
    ),
  { ssr: false }
);

const SignUpOrganization = dynamic(
  () => import('components/SignUp/SignUpOrganization/SignUpOrganization'),
  { ssr: false }
);
const SignUpOrganizationEmail = dynamic(
  () =>
    import(
      'components/SignUp/SignUpOrganization/SignUpOrganizationEmail/SignUpOrganizationEmail'
    ),
  { ssr: false }
);
const SignUpOrganizationSocial = dynamic(
  () =>
    import(
      'components/SignUp/SignUpOrganization/SignUpOrganizationSocial/SignUpOrganizationSocial'
    ),
  { ssr: false }
);

const SignUpContentCreator = dynamic(
  () => import('components/SignUp/SignUpContentCreator/SignUpContentCreator'),
  { ssr: false }
);

const SignUpContentCreatorEmail = dynamic(
  () =>
    import(
      'components/SignUp/SignUpContentCreator/SignUpContentCreatorEmail/SignUpContentCreatorEmail'
    ),
  { ssr: false }
);

const SignUpContentCreatorSocial = dynamic(
  () =>
    import(
      'components/SignUp/SignUpContentCreator/SignUpContentCreatorSocial/SignUpContentCreatorSocial'
    ),
  { ssr: false }
);

const ChooseSignUpPathModal = dynamic(
  () => import('components/SignUp/ChooseSignUpPathModal/ChooseSignUpPathModal'),
  { ssr: false }
);

export type Theme = 'black' | 'white';

type Props = {
  transparent?: boolean;
  fixed?: boolean;
  theme?: Theme;
  hidden?: boolean;
};

const Header: React.FC<Props> = ({
  transparent,
  fixed = true,
  theme = 'white',
  hidden = false,
}) => {
  return (
    <>
      <header
        className={cn({
          [styles.hidden]: hidden,
        })}
      >
        <DesktopHeader transparent={transparent} fixed={fixed} theme={theme} />
        <MobileHeader transparent={transparent} fixed={fixed} theme={theme} />
      </header>

      <ChooseSignUpPathModal />
      <SignUpSignIn />

      <SignUpAthlete />
      <SignUpAthleteEmail />
      <SignUpAthleteSocial />

      <SignUpOrganization />
      <SignUpOrganizationEmail />
      <SignUpOrganizationSocial />

      <SignUpContentCreator />
      <SignUpContentCreatorEmail />
      <SignUpContentCreatorSocial />

      <OnboardingWatcher />
      <GoogleSdkWatcher />
      <FacebookSdkWatcher />
      <TwitterSdkWatcher />
      <TiktokSdkWatcher />
      <ShoppingCartWatcher />
      <SyncBrowserTabsWatcher />
      <CookieTokenWatcher />
    </>
  );
};

export default Header;
