import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { parse } from 'query-string';
import { initFacebookSDK } from 'lib/facebook-sdk';
import { useMutation } from '@apollo/client';
// Api
import { FACEBOOK_SIGN_IN_V2 } from 'api/auth/mutations';
// Types
import {
  FacebookSignInV2,
  FacebookSignInV2Variables,
} from 'api/auth/types/FacebookSignInV2';
import { UserRole } from 'api/graphql-global-types';
// Constants
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helpers
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
} from 'helpers/storage';
import { trackFanSignUp } from 'helpers/metrics';
import { isStoreRole } from 'helpers/user';
// Hooks
import { useDefferInitialization, useAppContext } from 'hooks';

const FacebookSdkWatcher = (): null => {
  const { setToken } = useAppContext();
  const { asPath, push } = useRouter();

  const ready = useDefferInitialization();

  const [facebookSignInV2] = useMutation<
    FacebookSignInV2,
    FacebookSignInV2Variables
  >(FACEBOOK_SIGN_IN_V2);

  const parsedUrl = parse(asPath);
  const facebookAccessToken = parsedUrl['/?#access_token'] as string;

  // if 'redirect' login (build-in browsers)
  useEffect(() => {
    if (facebookAccessToken) {
      const login = async (fbAccessToken: string): Promise<void> => {
        try {
          const { data } = await facebookSignInV2({
            variables: { accessToken: fbAccessToken },
          });

          const user = data?.facebookSignInV2;
          const id = user?.id;
          const accessToken = user?.accessToken;
          const refreshToken = user?.refreshToken;
          const role = user?.role ?? null;

          if (id && accessToken && refreshToken) {
            const redirectPath = getRedirectFromLocalStorage();
            const isNewUser = !user.lastLoggedAt;

            setToken({ id, accessToken, refreshToken, role });

            if (isNewUser && user.role === UserRole.Customer) {
              trackFanSignUp(user);
            }

            if (isStoreRole(user.role)) {
              localStorage.setItem(STORE_USER_FIRST_LOGIN, 'true');
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
              });
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      };

      login(facebookAccessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facebookAccessToken]);

  useEffect(() => {
    const appId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;

    /**
     * Load scripts with a delay to improve page speed
     * performance & a metrics score
     */
    if (appId && ready) {
      initFacebookSDK({
        appId,
      }).catch((error) => {
        console.log('initFacebookSDK', error);
      });
    }
  }, [ready]);

  return null;
};

export default FacebookSdkWatcher;
