import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
// Types
import { Theme } from 'layouts/Header/Header';
// Hooks
import { useGetCurrUser, useLogout } from 'hooks';
// Helpers
import {
  isUserAthlete,
  getUserFullName,
  isActiveStore,
  isOnboardedStore,
  isUserOrganization,
  isUserContentCreator,
} from 'helpers/user';
import { isActiveRoute } from 'helpers/routes';
// Ui
import Icon from 'ui/Icon/Icon';
// Ui2
import LinkButton from 'ui2/LinkButton/LinkButton';
// Constants
import {
  PURCHASES,
  DASHBOARD,
  PROFILE,
  MY_SALES,
  MANAGE_MERCH,
  MANAGE_STREAMS,
  ACCOUNT_PRODUCTS,
  MANAGE_EXPERIENCES,
  MEDIA_LIBRARY,
  MANAGE_AMA,
  ACCOUNT_SETTINGS,
} from 'constants/routes';
import { HOST_A_PPV, ADVERTISE } from 'constants/external-links';

import styles from './LoggedUserMenu.module.scss';

export const customerLinks = [
  { label: 'Orders', href: PURCHASES },
  { label: 'Settings', href: ACCOUNT_SETTINGS },
];

type LoggedUserMenuProps = {
  theme?: Theme;
};

const LoggedUserMenu = ({ theme = 'white' }: LoggedUserMenuProps) => {
  const router = useRouter();
  const logout = useLogout();
  const { data } = useGetCurrUser();

  const userName = getUserFullName(data);
  const isAthlete = isUserAthlete(data);

  const isOrganization = isUserOrganization(data);
  const isContentCreator = isUserContentCreator(data);
  const isStore = isAthlete || isOrganization || isContentCreator;
  const isActive = isActiveStore(data);
  const isOnboarded = isOnboardedStore(data);
  const showStoreLinks = isAthlete || isOrganization || isContentCreator;

  const onboardedLinks = isOnboarded
    ? [
        { label: 'Merch', href: MANAGE_MERCH },
        { label: 'Brand Deals', href: MANAGE_AMA },
        {
          icon: 'photo-cam',
          label: 'channel',
          href: '/channel',
        },
      ]
    : [];

  const activeLinks = isActive
    ? [{ label: 'Streams', href: MANAGE_STREAMS }]
    : [];

  const storeLinks = [
    { label: 'Dashboard', href: DASHBOARD },
    { label: 'Profile', href: PROFILE },
    { label: 'Sales', href: MY_SALES },
    ...onboardedLinks,
    ...activeLinks,
    {
      icon: 'suitcase',
      label: 'Products',
      href: ACCOUNT_PRODUCTS,
    },
    {
      icon: 'user',
      label: 'Experiences',
      href: MANAGE_EXPERIENCES,
    },
    {
      icon: 'upload-file',
      label: 'Media Feed',
      href: MEDIA_LIBRARY,
    },
  ];

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <div className={cn(styles.root, { [styles[`theme-${theme}`]]: theme })}>
      {!isStore && (
        <>
          <LinkButton
            color={theme}
            href={HOST_A_PPV}
            xs="small"
            target="_blank"
            rel="noopener noreferrer"
          >
            HOST A PPV
          </LinkButton>
          <LinkButton
            color={theme}
            href={ADVERTISE}
            target="_blank"
            xs="small"
            rel="noopener noreferrer"
          >
            ADVERTISE WITH US
          </LinkButton>
        </>
      )}
      <div className={styles.menuWrapper} tabIndex={0} role="menuitem">
        <div className={styles.userNameBlock}>
          <span className={styles.userName}>{userName}</span>
          <Icon name="chevron-down-clean" className={styles.dropdownIcon} />
        </div>

        <ul className={styles.menu}>
          {showStoreLinks &&
            storeLinks.map(({ href, label }) => (
              <li
                key={label}
                className={cn(styles.link, {
                  [styles.active]: isActiveRoute(router.pathname, href),
                })}
              >
                <Link href={href} prefetch={false}>
                  <a>{label}</a>
                </Link>
              </li>
            ))}

          <>
            {customerLinks.map(({ href, label }) => (
              <li
                key={label}
                className={cn(styles.link, {
                  [styles.active]: isActiveRoute(router.pathname, href),
                })}
              >
                <Link href={href} prefetch={false}>
                  <a>{label}</a>
                </Link>
              </li>
            ))}
          </>

          <button className={styles.logoutButton} onClick={handleLogoutClick}>
            Log out
          </button>
        </ul>
      </div>
    </div>
  );
};

export default LoggedUserMenu;
