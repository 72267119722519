// Libs
import * as ga from 'lib/google-analytics';
import * as ttq from 'lib/ttpixel';
import * as fbq from 'lib/fpixel';
// Types
import { AmaType } from 'api/graphql-global-types';
import { SignUpAthleteV2_signUpAthleteV2 } from 'api/auth/types/SignUpAthleteV2';
import { SignUpCustomerV2_signUpCustomerV2 } from 'api/auth/types/SignUpCustomerV2';
import { GoogleSignInV2_googleSignInV2 } from 'api/auth/types/GoogleSignInV2';
import { FacebookSignInV2_facebookSignInV2 } from 'api/auth/types/FacebookSignInV2';
import { Me_me } from 'api/auth/types/Me';
import { MeProfileSetup_me } from 'api/auth/types/MeProfileSetup';
import { SignUpOrganization_signUpOrganization } from 'api/auth/types/SignUpOrganization';
import { SignUpContentCreator_signUpContentCreator } from 'api/auth/types/SignUpContentCreator';

export type TrackAddToCartAma = {
  id: string;
  price: number;
  type: AmaType;
  slug: string;
  storeName: string;
};

export type TrackAddToCartStream = {
  id: string;
  price: number;
  streamName: string;
  slug: string;
  storeName: string;
};

export const trackAddToCartAma = ({
  id,
  price,
  type,
  slug,
  storeName,
}: TrackAddToCartAma) => {
  ttq.addToCart({
    content_id: id,
    content_type: 'product',
    content_name: slug,
    content_category: 'Ama',
    quantity: 1,
    price: price,
    value: price,
    currency: 'USD',
  });

  fbq.addToCartAma({
    value: price,
    currency: 'USD',
    contents: [
      {
        id: id,
        quantity: 1,
        price: price,
        variant: type,
      },
    ],
    content_type: 'product',
    content_ids: [id],
  });

  ga.addToCart({
    currency: 'USD',
    value: price,
    items: [
      {
        id: id,
        name: storeName,
        category: 'Ama',
        brand: storeName,
        quantity: 1,
        price: price,
        variant: type,
      },
    ],
  });
};

export const trackAddToCartStream = ({
  id,
  price,
  streamName,
  slug,
  storeName,
}: TrackAddToCartStream) => {
  ttq.addToCart({
    content_id: id,
    content_type: 'product',
    content_name: slug,
    content_category: 'Watchstream',
    quantity: 1,
    price: price,
    value: price,
    currency: 'USD',
  });

  fbq.addToCartWatchstream({
    value: price,
    currency: 'USD',
    contents: [
      {
        id: id,
        quantity: 1,
        price: price,
      },
    ],
    content_type: 'product',
    content_ids: [id],
  });

  ga.addToCart({
    currency: 'USD',
    value: price,
    items: [
      {
        id: id,
        name: streamName,
        category: 'Watchstream',
        brand: storeName,
        quantity: 1,
        price: price,
      },
    ],
  });
};

export const trackStreamPurchase = ({
  id,
  price,
  streamName,
  slug,
  storeName,
}: TrackAddToCartStream) => {
  ttq.placeAnOrder({
    content_id: id,
    content_type: 'product',
    content_name: slug,
    content_category: 'Watchstream',
    quantity: 1,
    price: price,
    value: price,
    currency: 'USD',
  });

  ga.confirmedPurchase({
    currency: 'USD',
    value: price,
    items: [
      {
        id: id,
        name: streamName,
        category: 'Watchstream',
        brand: storeName,
        quantity: 1,
        price: price,
      },
    ],
  });
};

export const trackAthleteSignUp = (
  athlete: SignUpAthleteV2_signUpAthleteV2 | Me_me | MeProfileSetup_me
) => {
  const { id, email, firstName, lastName } = athlete;
  const fullName = `${firstName} ${lastName}`;

  ttq.completeRegistration({
    content_id: id,
    content_name: fullName,
    content_category: 'Athlete',
    content_type: 'product',
  });

  ga.signUp('athlete', {
    id,
    name: fullName,
    email: email || '',
  });
};

export const trackOrganizationSignUp = (
  organization: SignUpOrganization_signUpOrganization
) => {
  const { id, email, firstName, lastName } = organization;
  const fullName = `${firstName} ${lastName}`;

  ttq.completeRegistration({
    content_id: id,
    content_name: fullName,
    content_category: 'Organization',
    content_type: 'product',
  });

  ga.signUp('organization', {
    id,
    name: fullName,
    email,
  });
};

export const trackContentCreatorSignUp = (
  contentCreator: SignUpContentCreator_signUpContentCreator
) => {
  const { id, email, firstName, lastName } = contentCreator;
  const fullName = `${firstName} ${lastName}`;

  ttq.completeRegistration({
    content_id: id,
    content_name: fullName,
    content_category: 'Content Creator',
    content_type: 'product',
  });

  ga.signUp('content_creator', {
    id,
    name: fullName,
    email,
  });
};

export const trackFanSignUp = (
  customer:
    | GoogleSignInV2_googleSignInV2
    | SignUpCustomerV2_signUpCustomerV2
    | FacebookSignInV2_facebookSignInV2
) => {
  const { id, email, firstName, lastName } = customer;
  const fullName = `${firstName} ${lastName}`;

  ttq.completeRegistration({
    content_id: id,
    content_name: fullName,
    content_category: 'Fan',
    content_type: 'product',
  });

  ga.signUp('customer', {
    id,
    name: fullName,
    email,
  });
};
