import { useQuery, QueryResult, QueryOptions } from '@apollo/client';
import { useRouter } from 'next/router';
// Hooks
import { useRedirectOnError } from 'hooks';
// Api
import { GET_STREAM } from 'api/streams/queries';
// Types
import { GetStream, GetStreamVariables } from 'api/streams/types/GetStream';

type BasicQueryOptions = Omit<QueryOptions, 'query' | 'variables'>;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
  variables?: GetStreamVariables;
  skip?: boolean;
};

export const useGetStreamBySlug = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<GetStream, GetStreamVariables> => {
  const router = useRouter();
  const { streamSlug, athleteSlug } = router.query;

  const data = useQuery<GetStream, GetStreamVariables>(GET_STREAM, {
    variables: {
      ...options?.variables,
      input: {
        slug: streamSlug as string,
        storeSlug: athleteSlug as string,
      },
    },
    skip: !streamSlug || options?.skip,
    ...options?.options,
  });

  useRedirectOnError({
    error: data.error,
    skipTokenError: true,
    redirectTo: `/${athleteSlug}`,
  });

  return data;
};
