import { BaseEditor, Editor, Text } from 'slate';

export const isMarkActive = (editor: BaseEditor): boolean => {
  const marks: Omit<Text, 'text'> | null = Editor.marks(editor);
  // eslint-disable-next-line
  // @ts-ignore
  return marks ? marks['bold'] === true : false;
};

export const toggleMark = (editor: BaseEditor): void => {
  const isActive = isMarkActive(editor);

  if (isActive) {
    Editor.removeMark(editor, 'bold');
  } else {
    Editor.addMark(editor, 'bold', true);
  }
};
