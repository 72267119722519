import { useEffect } from 'react';
// Helpers
import { getTokenFromCookies } from 'helpers/cookies';
// Hooks
import { useAppContext, useLogout } from 'hooks';

const CookieTokenWatcher = () => {
  const { token, setToken, clearGuestToken } = useAppContext();
  const logout = useLogout();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const cookieToken = getTokenFromCookies();

      if (!token && cookieToken) {
        clearGuestToken();
        setToken(cookieToken);
      }

      if (token && !cookieToken) {
        logout();
      }

      if (token && cookieToken && token.id !== cookieToken.id) {
        setToken(cookieToken);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [token, setToken, clearGuestToken, logout]);

  return null;
};

export default CookieTokenWatcher;
