import cn from 'classnames';
// Ui
import Icon, { IconNames } from 'ui/Icon/Icon';

import styles from './SocialIcons.module.scss';

type SocialIconsProps = {
  className?: string;
  classNameIcon?: string;
};

const SocialIcons = ({ className, classNameIcon }: SocialIconsProps) => {
  const links: {
    href: string;
    title: string;
    icon: IconNames;
  }[] = [
    {
      href: 'https://www.facebook.com/millionsdotco',
      title: 'Learn more about Millions at Facebook',
      icon: 'facebook',
    },
    {
      href: 'https://www.instagram.com/millionsdotco/',
      title: 'Learn more about Millions at Instagram',
      icon: 'instagram',
    },
    {
      href: 'https://www.linkedin.com/company/millionsdotco/',
      title: 'Learn more about Millions at Linkedin',
      icon: 'linkedin',
    },
    {
      href: 'https://twitter.com/millionsdotco',
      title: 'Learn more about Millions at Twitter',
      icon: 'twitter',
    },
    {
      href: 'https://www.youtube.com/channel/UCyIadicC0Ac0R4yU3-cqRgA',
      title: 'Learn more about Millions at Youtube',
      icon: 'youtube',
    },
    {
      href: 'https://www.tiktok.com/@millionsdotco',
      title: 'Learn more about Millions at Tiktok',
      icon: 'tiktok',
    },
  ];

  return (
    <ul className={cn(styles.socialIcons, className)}>
      {links.map(({ href, title, icon }) => (
        <li key={href} className={styles.socialIconWrapper}>
          <a
            href={href}
            title={title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              name={icon}
              className={cn(styles.socialIcon, classNameIcon)}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialIcons;
