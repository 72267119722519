import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
// Styles
import styles from './Text.module.scss';

type Color =
  | 'white'
  | 'light-white'
  | 'black'
  | 'god-grey'
  | 'black-gold'
  | 'dusty-grey'
  | 'gainsboro'
  | 'alto'
  | 'harvest-gold'
  | 'chateau-green'
  | 'maximum-red';

type TextSize =
  | 'extra-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | '2x-large'
  | '3x-large'
  | '4x-large'
  | '5x-large';

type TextProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  isEllipsis?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  bold?: boolean;
  xxs?: TextSize;
  xs?: TextSize;
  s?: TextSize;
  m?: TextSize;
  ml?: TextSize;
  l?: TextSize;
  xl?: TextSize;
  xxl?: TextSize;
  color?: Color;
  textAlign?: 'center' | 'right';
};

const Text = ({
  children,
  isEllipsis,
  isError,
  isSuccess,
  uppercase,
  lowercase,
  bold,
  color = 'white',
  xxs = 'medium',
  xs,
  s,
  m,
  ml,
  l,
  xl,
  xxl,
  textAlign,
  className,
  ...rest
}: TextProps) => {
  return (
    <p
      className={cn(
        styles.text,
        {
          [styles.ellipsis]: isEllipsis,
          [styles.error]: isError,
          [styles.success]: isSuccess,
          [styles.uppercase]: uppercase,
          [styles.lowercase]: lowercase,
          [styles.bold]: bold,
          [styles[`color-${color}`]]: color,
          [styles[`size-xxs-${xxs}`]]: xxs,
          [styles[`size-xs-${xs}`]]: xs,
          [styles[`size-s-${s}`]]: s,
          [styles[`size-m-${m}`]]: m,
          [styles[`size-ml-${ml}`]]: ml,
          [styles[`size-l-${l}`]]: l,
          [styles[`size-xl-${xl}`]]: xl,
          [styles[`size-xxl-${xxl}`]]: xxl,
          [styles[`align-${textAlign}`]]: textAlign,
        },
        className
      )}
      {...rest}
    >
      {children}
    </p>
  );
};

export default Text;
