import Link from 'next/link';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
//Api
import { GET_MERCH_CART_ITEM_LIST } from 'api/merch/queries';
// Types
import { StreamStatus } from 'api/graphql-global-types';
import { GetMerchCartItemsList } from 'api/merch/types/GetMerchCartItemsList';
import { Theme } from 'layouts/Header/Header';
// Constants
import { HOME } from 'constants/routes';
// Helper
import { withCDN } from 'helpers/cdn';
import { getCorrectCartCount } from 'helpers/cart';
import {
  isActiveStore,
  isUserOrganization,
  isUserContentCreator,
  isUserAthlete,
} from 'helpers/user';
import { getMerchShoppingCartFromLocalStorage } from 'helpers/storage';
// Hooks
import { useAppContext, useGetCurrUser, useGetStreamBySlug } from 'hooks';
// Layouts
import ResolutionWrapper from 'layouts/ResolutionWrapper/ResolutionWrapper';
// Ui
import Image from 'ui/Image/Image';
// Components
import DesktopNavigation from './DesktopNavigation/DesktopNavigation';
import LoggedUserMenu from './LoggedUserMenu/LoggedUserMenu';
import UnLoggedUserButtons from 'layouts/Header/UnLoggedUserButtons/UnLoggedUserButtons';
import ShoppingCartButton from 'layouts/Header/ShoppingCartButton/ShoppingCartButton';
import GoLiveButton from './DesktopNavigation/GoLiveButton/GoLiveButton';
// Styles
import styles from './DesktopHeader.module.scss';

type DesktopHeaderProps = {
  transparent?: boolean;
  fixed?: boolean;
  theme?: Theme;
};

export const DesktopHeader = ({
  transparent,
  fixed,
  theme = 'white',
}: DesktopHeaderProps) => {
  const { data: currentUser } = useGetCurrUser();
  const { guestToken } = useAppContext();

  const { data: streamData } = useGetStreamBySlug({
    options: {
      fetchPolicy: 'cache-first',
    },
  });
  // used to get shopping cart size for unlogged user
  const merchShoppingCart = getMerchShoppingCartFromLocalStorage();
  // used to get shopping cart size for guest user
  const { data: merchShoppingCartData } = useQuery<GetMerchCartItemsList>(
    GET_MERCH_CART_ITEM_LIST,
    {
      skip: !guestToken,
    }
  );

  const isOrganization = isUserOrganization(currentUser);
  const isContentCreator = isUserContentCreator(currentUser);
  const isAthlete = isUserAthlete(currentUser);
  const isActive = isActiveStore(currentUser);
  const canGoLive =
    isActive && (isAthlete || isOrganization || isContentCreator);
  const streamStarted =
    streamData?.stream?.streamStatus === StreamStatus.Active;
  const streamPaused = streamData?.stream?.streamStatus === StreamStatus.Paused;

  const isLoggedIn = Boolean(currentUser?.me?.id);
  const isStreamLive = streamStarted || streamPaused;

  const anonymousCartCount = getCorrectCartCount(merchShoppingCart);
  const guestCartCount = getCorrectCartCount(
    merchShoppingCartData?.getMerchCartItemsList
  );

  const shoppingCartCounter = isLoggedIn
    ? currentUser?.me?.cartSize
    : guestToken
    ? guestCartCount
    : anonymousCartCount;

  return (
    <div
      className={cn(styles.root, {
        [styles[`theme-${theme}`]]: theme,
        [styles.transparent]: transparent,
        [styles.fixed]: fixed,
      })}
    >
      <ResolutionWrapper className={styles.resolutionWrapper}>
        <div className={styles.logoWrapper}>
          <Link href={HOME} passHref prefetch={false}>
            <a>
              <Image
                className={styles.logo}
                src={
                  theme === 'white'
                    ? withCDN('/images/logos/millions-logo-black.svg')
                    : withCDN('/images/logos/millions-logo.svg')
                }
                alt="MILLIONS logo"
                priority
                unoptimized
              />
            </a>
          </Link>
        </div>

        <div className={styles.navWrapper}>
          <div className={styles.navAuthWrapper}>
            {isLoggedIn ? (
              <LoggedUserMenu theme={theme} />
            ) : (
              <UnLoggedUserButtons
                theme={theme}
                className={styles.authButtons}
              />
            )}
          </div>
          <div className={styles.navMenuWrapper}>
            <DesktopNavigation theme={theme} />
            <div className={styles.navMenuRightButtons}>
              {canGoLive && !isStreamLive ? <GoLiveButton /> : null}

              <ShoppingCartButton
                merchCounter={shoppingCartCounter}
                theme={theme}
                className={styles.shoppingCartButton}
              />
            </div>
          </div>
        </div>
      </ResolutionWrapper>
    </div>
  );
};

export default DesktopHeader;
