import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  RenderElementProps,
} from 'slate-react';
import { createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import cn from 'classnames';
// Helpers
import {
  serialize,
  deserialize,
  withInlines,
  createParagraphNode,
} from 'components/common2/RichText/helpers/basic';
import { isJsonString } from 'helpers/textEditor';
// Types
import { PlacesType } from 'react-tooltip';
// Components
import Leaf, {
  LeafProps,
} from 'components/common2/RichText/components/Leaf/Leaf';
import Toolbar, {
  ToolbarItem,
} from 'components/common2/RichText/components/Toolbar/Toolbar';
import Element from 'components/common2/RichText/components/Element/Element';
// Styles
import styles from './RichText.module.scss';

export type RichTextProps = {
  onChange: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  initialValue?: string;
  reset?: boolean;
  toolbarItems?: ToolbarItem[];
  classNameWrapper?: string;
  classNameEditor?: string;
  error?: string;
  label?: string;
  toolbarPosition?: PlacesType;
};

// Note: this component can't be covered by unit tests for now because
// the issue caused by the Editable component from the 'slate-react' package
const RichText = ({
  readOnly,
  placeholder = '',
  onChange,
  initialValue,
  reset,
  toolbarItems,
  classNameWrapper = '',
  classNameEditor = '',
  error = '',
  label,
  toolbarPosition,
}: RichTextProps): React.ReactElement => {
  const [value, setValue] = useState<Node[]>([createParagraphNode('')]);
  const [editor] = useState<ReactEditor>(() =>
    withInlines(withHistory(withReact(createEditor() as ReactEditor)))
  );
  const showToolbar = Boolean(toolbarItems);

  const renderLeaf = useCallback<(props: LeafProps) => JSX.Element>(Leaf, []);
  const renderElement = useCallback<(props: RenderElementProps) => JSX.Element>(
    Element,
    []
  );

  const mountRef = useRef<boolean>(false);

  useEffect(() => {
    if ((initialValue && !mountRef.current) || (reset && initialValue)) {
      const isJson = isJsonString(initialValue);

      let initialEditorValue = [createParagraphNode(initialValue)];

      if (isJson) {
        const parsedJSON = JSON.parse(initialValue);
        const document = new DOMParser().parseFromString(
          parsedJSON,
          'text/html'
        );

        initialEditorValue = deserialize(document.body);
      }

      setValue(initialEditorValue);
      mountRef.current = true;
    }
  }, [initialValue, reset]);

  const handleChange = (value: Node[]) => {
    const serializedValue = serialize(editor);
    const content = JSON.stringify(serializedValue);
    const temp = document.createElement('div');

    temp.innerHTML = serializedValue;
    const sanitized = temp.textContent || temp.innerText;
    const contentValue = sanitized.trim().length ? content : '';

    setValue(value);
    onChange(contentValue);
  };

  return (
    <div className={styles.root}>
      {label && <label className={styles.label}>{label}</label>}

      <div className={cn(styles.editorWrapper, classNameWrapper)}>
        <Slate editor={editor} value={value} onChange={handleChange}>
          {showToolbar && (
            <Toolbar items={toolbarItems} toolbarPosition={toolbarPosition} />
          )}

          <Editable
            className={cn(styles.editor, classNameEditor)}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            readOnly={readOnly}
          />
        </Slate>
      </div>
      {error && <p className={styles.errorHint}>{error}</p>}
    </div>
  );
};

export default RichText;
