import { useContext } from 'react';
// Context
import { AppContext, AppContextType } from 'context/AppContext';

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppContext Provider');
  }

  return context;
};
