import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// Api
import { GET_MERCH_CART_ITEM_LIST } from 'api/merch/queries';
import { MemorabiliaProductType } from 'api/graphql-global-types';
// Types
import { Theme } from 'layouts/Header/Header';
import { GetMerchCartItemsList } from 'api/merch/types/GetMerchCartItemsList';
// Hooks
import { useAppContext, useGetCurrUser, useLogout } from 'hooks';
// Helpers
import { getCorrectCartCount } from 'helpers/cart';
import {
  isUserAthlete,
  isActiveStore,
  isOnboardedStore,
  isUserContentCreator,
  isUserOrganization,
} from 'helpers/user';
import { getMerchShoppingCartFromLocalStorage } from 'helpers/storage';
import { isActiveRoute } from 'helpers/routes';
import { withCDN } from 'helpers/cdn';
// Constants
import {
  HOME,
  ATHLETES,
  MERCH,
  STREAMS,
  ORGANIZATIONS,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  DASHBOARD,
  PROFILE,
  MY_SALES,
  MANAGE_MERCH,
  MANAGE_STREAMS,
  PRODUCTS,
  ACCOUNT_PRODUCTS,
  EXPERIENCES,
  MANAGE_EXPERIENCES,
  MEDIA_LIBRARY,
  AMAS,
  MANAGE_AMA,
  CHANNEL,
} from 'constants/routes';
import {
  BLOG,
  CAREERS,
  ABOUT_US,
  ADVERTISE,
  HOST_A_PPV,
} from 'constants/external-links';
import { customerLinks } from '../DesktopHeader/LoggedUserMenu/LoggedUserMenu';
// Ui
import Image from 'ui/Image/Image';
import Collapse from 'ui/Collapse/Collapse';
// Ui2
import Button from 'ui2/Button/Button';
// Components
import UnLoggedUserButtons from 'layouts/Header/UnLoggedUserButtons/UnLoggedUserButtons';
import ShoppingCartButton from 'layouts/Header/ShoppingCartButton/ShoppingCartButton';
import SocialIcons from 'components/common2/SocialIcons/SocialIcons';
// Styles
import styles from './MobileHeader.module.scss';

type MobileHeaderProps = {
  theme?: Theme;
  transparent?: boolean;
  fixed?: boolean;
};

const MobileHeader = ({
  transparent,
  fixed,
  theme = 'white',
}: MobileHeaderProps) => {
  const { pathname, query } = useRouter();
  const { guestToken } = useAppContext();
  const logout = useLogout();
  const [isMobileMenuOpen, toggleMobileMenu] = useState<boolean>(false);
  const { data } = useGetCurrUser();
  const merchShoppingCart = getMerchShoppingCartFromLocalStorage();

  const isLoggedIn = Boolean(data?.me?.id);
  const isAthlete = isUserAthlete(data);

  const isOrganization = isUserOrganization(data);
  const isContentCreator = isUserContentCreator(data);
  const isStore = isAthlete || isOrganization || isContentCreator;
  const isActive = isActiveStore(data);
  const isOnboarded = isOnboardedStore(data);
  const showStoreLinks = isAthlete || isOrganization || isContentCreator;
  const navRef = useRef<HTMLDivElement | null>(null);

  // used to get shopping cart size for guest user
  const { data: merchShoppingCartData } = useQuery<GetMerchCartItemsList>(
    GET_MERCH_CART_ITEM_LIST,
    {
      skip: !guestToken,
    }
  );

  const anonymousCartCount = getCorrectCartCount(merchShoppingCart);
  const guestCartCount = getCorrectCartCount(
    merchShoppingCartData?.getMerchCartItemsList
  );

  const shoppingCartCounter = isLoggedIn
    ? data?.me?.cartSize
    : guestToken
    ? guestCartCount
    : anonymousCartCount;

  const isStoreLinks = isStore
    ? []
    : [
        { label: 'Advertise with us', href: ADVERTISE, subMenu: null },
        { label: 'Host a PPV', href: HOST_A_PPV, subMenu: null },
      ];

  const pageLinks = [
    { label: 'Home', href: HOME, subMenu: null },
    { label: 'Athletes', href: ATHLETES, subMenu: null },
    { label: 'Organizations', href: ORGANIZATIONS, subMenu: null },
    {
      label: 'Shop',
      href: null,
      subMenu: [
        {
          label: 'Merch',
          href: {
            pathname: MERCH,
          },
        },
        {
          label: 'Products',
          href: {
            pathname: PRODUCTS,
            query: { productType: MemorabiliaProductType.Product },
          },
        },
        {
          label: 'Memorabilia',
          href: {
            pathname: PRODUCTS,
            query: { productType: MemorabiliaProductType.Memorabilia },
          },
        },
        {
          label: 'Experiences',
          href: {
            pathname: EXPERIENCES,
          },
        },
        {
          label: 'Personal Videos',
          href: {
            pathname: AMAS,
          },
        },
      ],
    },
    {
      label: 'Watch',
      href: STREAMS,
      subMenu: null,
    },
    ...isStoreLinks,
    {
      label: 'Company',
      href: null,
      subMenu: [
        {
          label: 'News',
          href: BLOG,
        },
        {
          label: 'About us',
          href: ABOUT_US,
        },
        {
          label: 'Careers',
          href: CAREERS,
        },
      ],
    },
  ];

  const onboardedLinks = isOnboarded
    ? [
        { label: 'Merch', href: MANAGE_MERCH },
        { label: 'Brand Deals', href: MANAGE_AMA },
        {
          label: 'channel',
          href: CHANNEL,
        },
      ]
    : [];

  const activeLinks = isActive
    ? [{ label: 'Streams', href: MANAGE_STREAMS }]
    : [];

  const storeLinks = [
    { label: 'Dashboard', href: DASHBOARD },
    { label: 'Profile', href: PROFILE },
    { label: 'Sales', href: MY_SALES },
    ...onboardedLinks,
    ...activeLinks,
    {
      icon: 'suitcase',
      label: 'Products',
      href: ACCOUNT_PRODUCTS,
    },
    {
      icon: 'user',
      label: 'Experiences',
      href: MANAGE_EXPERIENCES,
    },
    {
      icon: 'upload-file',
      label: 'Media Feed',
      href: MEDIA_LIBRARY,
    },
  ];

  const isActiveProfileMenuLink = customerLinks.some((item) =>
    isActiveRoute(pathname, item.href)
  );

  useEffect(() => {
    const ref = navRef.current;

    if (ref) {
      if (isMobileMenuOpen) {
        disableBodyScroll(ref);
      } else {
        enableBodyScroll(ref);
      }
    }

    return () => {
      if (ref) {
        enableBodyScroll(ref);
      }
    };
  }, [isMobileMenuOpen]);

  const handleMobileMenuToggle = (): void => {
    toggleMobileMenu((prev) => !prev);
  };

  const handleLogoutButtonClick = () => {
    logout();
  };

  return (
    <div
      className={cn(styles.root, {
        [styles[`theme-${theme}`]]: theme,
        [styles.transparent]: transparent,
        [styles.fixed]: fixed,
        [styles.open]: isMobileMenuOpen,
      })}
    >
      <div className={styles.logoWrapper}>
        <Link href={HOME} passHref prefetch={false}>
          <a>
            <Image
              className={styles.logo}
              src={
                theme === 'white'
                  ? withCDN('/images/logos/mobile-header-logo.svg')
                  : withCDN('/images/logos/mobile-logo-white.svg')
              }
              alt="MILLIONS logo"
              priority
              unoptimized
            />
          </a>
        </Link>
      </div>

      <div className={styles.menuWrapper}>
        <button
          className={cn(styles.menuToggle, {
            [styles.open]: isMobileMenuOpen,
          })}
          onClick={handleMobileMenuToggle}
          aria-label="toggle-button for mobile menu"
        >
          <span />
          <span />
          <span />
        </button>

        <ShoppingCartButton theme={theme} merchCounter={shoppingCartCounter} />
      </div>

      <nav
        ref={navRef}
        className={cn(styles.nav, {
          [styles.open]: isMobileMenuOpen,
        })}
      >
        {pageLinks.map(({ href, label, subMenu }) => {
          if (subMenu) {
            const isActiveParentMenuLink = subMenu.some((item) =>
              isActiveRoute(
                pathname,
                item.href.pathname,
                item?.href?.query?.productType,
                query?.productType
              )
            );

            return (
              <Collapse
                key={label}
                title={
                  <p
                    className={cn(styles.parentMenuLink, {
                      [styles.active]: isActiveParentMenuLink,
                    })}
                  >
                    {label}
                  </p>
                }
                className={styles.collapseWrapper}
                bodyClassName={styles.subMenu}
              >
                <ul>
                  {subMenu.map((item) => {
                    if (item.href?.pathname?.startsWith('http')) {
                      return (
                        <a
                          title={item.label}
                          key={item.label}
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.childMenuLink}
                        >
                          {item.label}
                        </a>
                      );
                    } else {
                      return (
                        <button
                          key={item.label}
                          onClick={handleMobileMenuToggle}
                          className={cn(styles.childMenuLink, {
                            [styles.active]: isActiveRoute(
                              pathname,
                              item.href.pathname,
                              item.href?.query?.productType,
                              query?.productType
                            ),
                          })}
                        >
                          <Link href={item.href} prefetch={false}>
                            <a title={item.label}>{item.label}</a>
                          </Link>
                        </button>
                      );
                    }
                  })}
                </ul>
              </Collapse>
            );
          }

          // Watch button is inverted if user is not a stores(athlete/org/content creator)
          const isInvertedWatchButton = label === 'Watch' && !isStore;

          if (href) {
            return (
              <Link key={label} href={href} prefetch={false}>
                <a
                  target={href.startsWith('http') ? '_blank' : '_self'}
                  rel={href.startsWith('http') ? 'noopener noreferrer' : ''}
                  className={cn(styles.link, {
                    [styles.active]: isActiveRoute(pathname, href),
                    [styles.inverted]: isInvertedWatchButton,
                  })}
                >
                  {label}
                </a>
              </Link>
            );
          }
        })}

        {isLoggedIn && (
          <Collapse
            title={
              <p
                className={cn(styles.parentMenuLink, {
                  [styles.active]: isActiveProfileMenuLink,
                })}
              >
                Profile
              </p>
            }
            className={cn(
              styles.collapseWrapper,
              styles.collapseProfileWrapper
            )}
            bodyClassName={styles.subMenu}
          >
            <ul>
              {showStoreLinks &&
                storeLinks.map(({ href, label }) => (
                  <li
                    key={label}
                    className={cn(styles.childMenuLink, {
                      [styles.active]: isActiveRoute(pathname, href),
                    })}
                  >
                    <Link href={href} prefetch={false}>
                      <a>{label}</a>
                    </Link>
                  </li>
                ))}

              <>
                {customerLinks.map(({ href, label }) => (
                  <li
                    key={label}
                    className={cn(styles.childMenuLink, {
                      [styles.active]: isActiveRoute(pathname, href),
                    })}
                  >
                    <Link href={href} prefetch={false}>
                      <a title={label}>{label}</a>
                    </Link>
                  </li>
                ))}
              </>
            </ul>
          </Collapse>
        )}

        {!isLoggedIn && (
          <UnLoggedUserButtons
            theme={theme}
            buttonSize="small"
            className={styles.authButtonWrapper}
            buttonClassName={styles.authButton}
            onToggleMobileMenu={toggleMobileMenu}
          />
        )}

        {isLoggedIn && (
          <Button
            xs="small"
            color={theme}
            onClick={handleLogoutButtonClick}
            className={styles.logoutButton}
          >
            Log out
          </Button>
        )}

        <SocialIcons
          className={styles.socialIconWrapper}
          classNameIcon={styles.socialIcon}
        />

        <div className={styles.externalLinkWrapper}>
          <Link href={PRIVACY_POLICY} prefetch={false}>
            <a className={styles.externalLink}>Privacy policy</a>
          </Link>
          <Link href={TERMS_OF_SERVICE} prefetch={false}>
            <a className={styles.externalLink}>Terms of service</a>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default MobileHeader;
