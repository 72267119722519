import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
// Api
import { TWITTER_SIGN_IN, STORE_TWITTER_SIGN_UP } from 'api/auth/mutations';
// Types
import {
  TwitterSignIn,
  TwitterSignInVariables,
  TwitterSignIn_twitterSignIn,
} from 'api/auth/types/TwitterSignIn';
import {
  StoreTwitterSignUp,
  StoreTwitterSignUpVariables,
  StoreTwitterSignUp_storeTwitterSignUp,
} from 'api/auth/types/StoreTwitterSignUp';
import { UserRole } from 'api/graphql-global-types';
// Constants
import { STORE_USER_FIRST_LOGIN } from 'constants/auth';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import {
  getRedirectFromLocalStorage,
  clearRedirectFromLocalStorage,
  getStoreSocialSignupFromLocalStorage,
  clearStoreSocialSignupFromLocalStorage,
} from 'helpers/storage';
import { trackFanSignUp } from 'helpers/metrics';
import { isStoreRole } from 'helpers/user';
// Hooks
import { useAppContext } from 'hooks';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';

const TwitterSdkWatcher = (): null | JSX.Element => {
  const { setToken } = useAppContext();
  const { push, query } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const [twitterSignIn] = useMutation<TwitterSignIn, TwitterSignInVariables>(
    TWITTER_SIGN_IN
  );
  const [storeTwitterSignUp] = useMutation<
    StoreTwitterSignUp,
    StoreTwitterSignUpVariables
  >(STORE_TWITTER_SIGN_UP);

  const oauthToken = query.oauth_token as string;
  const oauthVerifier = query.oauth_verifier as string;

  useEffect(() => {
    if (oauthToken && oauthVerifier) {
      setLoading(true);

      const login = async (): Promise<void> => {
        try {
          const role = getStoreSocialSignupFromLocalStorage();

          let user:
            | StoreTwitterSignUp_storeTwitterSignUp
            | TwitterSignIn_twitterSignIn
            | undefined;
          let id: string | undefined;
          let accessToken: string | undefined;
          let refreshToken: string | undefined;

          if (isStoreRole(role as UserRole | null)) {
            const { data } = await storeTwitterSignUp({
              variables: {
                input: {
                  oauth_token: oauthToken,
                  oauth_verifier: oauthVerifier,
                  role: role as UserRole,
                },
              },
            });

            user = data?.storeTwitterSignUp;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          } else {
            const { data } = await twitterSignIn({
              variables: {
                input: {
                  oauth_token: oauthToken,
                  oauth_verifier: oauthVerifier,
                },
              },
            });

            user = data?.twitterSignIn;
            id = user?.id;
            accessToken = user?.accessToken;
            refreshToken = user?.refreshToken;
          }

          if (id && accessToken && refreshToken) {
            const redirectPath = getRedirectFromLocalStorage();
            const isNewUser = !user?.lastLoggedAt;

            setToken({ id, accessToken, refreshToken, role: role as UserRole });

            if (isNewUser && user?.role === UserRole.Customer) {
              trackFanSignUp(user);
            }

            if (isStoreRole(user?.role)) {
              localStorage.setItem(STORE_USER_FIRST_LOGIN, 'true');
            }

            if (redirectPath) {
              push(redirectPath).then(() => {
                clearRedirectFromLocalStorage();
                clearStoreSocialSignupFromLocalStorage();
              });
            } else {
              push({
                query: '',
              });
              clearStoreSocialSignupFromLocalStorage();
            }
          }
        } catch (error) {
          push({
            query: '',
          });

          showToast({
            message: getProperErrorMessage(
              error,
              'Looks like something went wrong. Please try again later.'
            ),
            type: 'error',
          });
          console.log('error', error);
          Sentry.captureMessage(`Twitter sign up: ${JSON.stringify(error)}`, {
            tags: {
              section: 'auth',
            },
          });
          clearStoreSocialSignupFromLocalStorage();
        }

        setLoading(false);
      };

      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauthToken, oauthVerifier]);

  if (loading) {
    return <FourDotsLoader />;
  }

  return null;
};

export default TwitterSdkWatcher;
