import { useSlate } from 'slate-react';
import cn from 'classnames';
// Helpers
import {
  toggleMark,
  isMarkActive,
} from 'components/common2/RichText/helpers/mark';
// Ui
import Button from 'ui2/Button/Button';
import Icon from 'ui/Icon/Icon';
// Styles
import styles from './MarkButton.module.scss';

const MarkButton = () => {
  const editor = useSlate();
  const isActive = isMarkActive(editor);

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleMark(editor);
  };

  return (
    <span className={styles.root}>
      <Button
        className={cn(styles.button, { [styles.isActive]: isActive })}
        xs="xSmall"
        color={isActive ? 'black' : 'white'}
        onMouseDown={onMouseDown}
        type="button"
      >
        <Icon
          className={cn(styles.icon, { [styles.isActive]: isActive })}
          name="alert"
        />
      </Button>
    </span>
  );
};

export default MarkButton;
