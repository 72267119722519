import React from 'react';
import cn from 'classnames';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';
import styles from './Modal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  focusTrapped?: boolean;
  className?: string;
  closeOnOverlayClick?: boolean;
};

const Modal: React.FC<Props> = ({
  title,
  subTitle,
  open,
  children,
  onClose,
  size = 'medium',
  focusTrapped = true,
  className,
  closeOnOverlayClick = true,
}) => {
  return (
    <ResponsiveModal
      center
      open={open}
      classNames={{
        overlay: styles.overlay,
        modal: cn(styles.modal, styles[`size-${size}`], className),
        closeIcon: styles.closeIcon,
        closeButton: styles.closeButton,
      }}
      onClose={onClose}
      focusTrapped={focusTrapped}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      {title && (
        <h5 className={cn(styles.title, { [styles.withSubTitle]: subTitle })}>
          {title}
        </h5>
      )}
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      {children}
    </ResponsiveModal>
  );
};

export default Modal;
