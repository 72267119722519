import { useEffect, useRef, useState } from 'react';
// Helpers
import { withCDN } from 'helpers/cdn';
// Styles
import styles from './StreamCoverImage.module.scss';

const StreamCoverImage = ({ previewImage, fullname, onSetNewImage }) => {
  const [newImage, setNewImage] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = previewImage;
    img.onload = () => {
      if (canvas && ctx) {
        canvas.width = 400;
        canvas.height = 400;
        ctx.drawImage(img, 0, 0, 400, 400);
        ctx.font = 'bold 24px GT America';
        ctx.textAlign = 'center';
        const logo = new Image();
        logo.crossOrigin = 'anonymous';
        logo.src = withCDN('/images/logos/millions-logo.svg');
        ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        logo.onload = () => {
          ctx.globalAlpha = 1;
          ctx.drawImage(
            logo,
            canvas.width / 2 - logo.width / 2,
            canvas.height / 2 - logo.height / 2
          );
          ctx.fillStyle = 'rgb(210, 171, 100)';
          ctx.fillText(fullname, canvas.width / 2, canvas.height - 40);
          // Draw border
          ctx.strokeStyle = 'rgb(210, 171, 100)';
          ctx.lineWidth = 10;
          ctx.strokeRect(0, 0, canvas.width, canvas.height);
          setNewImage(canvas.toDataURL());
          // Extract the base64 data from the data URL
          const base64Data = newImage?.split(',')[1];
          // Decode the base64 data and create a Uint8Array
          if (base64Data) {
            const decodedData = atob(base64Data);
            const uint8Array = new Uint8Array(decodedData.length);
            for (let i = 0; i < decodedData.length; i++) {
              uint8Array[i] = decodedData.charCodeAt(i);
            }
            // Create a Blob from the Uint8Array
            const blob = new Blob([uint8Array], { type: 'image/jpeg' });
            // Create a File from the Blob
            const fileName = `${fullname}liveStreamCover.jpg`;
            const file = new File([blob], fileName, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            onSetNewImage(file);
          }
        };
      }
    };
  }, [previewImage, fullname, onSetNewImage, newImage]);

  return (
    <div>
      <canvas ref={canvasRef} className={styles.canvas} data-testid="canvas" />
      {newImage && (
        <div>
          <img src={newImage} alt="Stream poster" />
        </div>
      )}
    </div>
  );
};

export default StreamCoverImage;
